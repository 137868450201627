@font-face {
  font-family: 'RitualAF';
  src: url(./assets/fonts/RitualAF.otf);
}

@font-face {
  font-family: 'DINNext';
  src: url(./assets/fonts/DINNextW01-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'DINNext';
  src: url(./assets/fonts/DINNextW01-Bold.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'DINNext';
  src: url(./assets/fonts/DINNextW01-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'DINNext';
  src: url(./assets/fonts/DINNextW01-LightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DINNext';
  src: url(./assets/fonts/DINNextW01-Light.ttf);
  font-weight: 200;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  font-family: 'DINNEXT', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #201c1c;
  color: rgba(255, 255, 255, 0.87);
}
